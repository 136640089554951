/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

// React-oidc polyfills
import 'core-js/es/array/from';
import 'core-js/es/array/find';
import 'core-js/es/array/includes';
import 'core-js/es/array/find-index';
import 'core-js/es/array/map';
import 'core-js/es/object/assign';
import 'core-js/es/promise';
import 'core-js/es/map';
import 'core-js/es/string/repeat';
import 'core-js/es/string/pad-start';
import 'core-js/es/string/pad-end';
import 'core-js/es/string/starts-with';
import 'whatwg-fetch';

const polyfillEvent = () => {
  function CustomEvent(
    event: string,
    params: { bubbles: boolean; cancelable: boolean; detail: boolean },
  ) {
    const { bubbles = false, cancelable = false, detail = false } =
      params || {};
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, bubbles, cancelable, detail);
    return evt;
  }

  // If not IE
  if (typeof window.CustomEvent !== 'function') {
    CustomEvent.prototype = window.Event.prototype;

    // @ts-ignore
    window.CustomEvent = CustomEvent;
    // @ts-ignore
    window.Event = CustomEvent;
  }
};

polyfillEvent();

/**
 * This bootstrap import gives Webpack an opportunity to process imports in
 * the App and load all remotes before execution.
 */
import('./bootstrap');
